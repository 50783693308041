import {Component, OnInit} from '@angular/core';
import {BookingService} from '../appservices/booking.service';
import {Router} from '@angular/router';
import {LoaderService} from '../sharedcomponents/index';
import * as moment from 'moment-timezone';

import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SharedProvider } from '../appservices/shared';

@Component({selector: 'app-bookings', 
templateUrl: './dashboard.component.html', 
styleUrls: ['./dashboard.component.scss'],
styles: [`

:host /deep/ ng2-smart-table { font-size : 16px }

`]})
export class DashboardComponent implements OnInit {
  bookings = [];
  public moment : any = moment;
  filteredbookings = [];
  searchby : any = "requestDate";
  searchvalue :any;
  fromRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  toRequestDate : any = this
  .moment().tz('Asia/Kolkata')
  .format('YYYY-MM-DD');
  smartTablesettings : any;
  constructor(private _bookingservice : BookingService, private loader : LoaderService, private sharedService : SharedProvider) {
    this.smartTablesettings = {
      attr: {
        class: 'table table-bordered'
        },
      noDataMessage:"No Booking Request available",
      actions: {
        add:false,
        edit:false,
        delete:false
      },
      columns: {
        bookingid: {
          title: 'Booking Id'
        },
        customerName: {
          title: 'Name'
        },
        spaceName: {
          title: 'Space'
        },
        gofloatersSpaceName: {
          title : 'GoFloaters Space Name'
        },
        spaceDisplayName: {
          title : 'Space Display Name'
        },
        originalName: {
          title : 'Original Name'
        },
        location: {
          title: 'Location'
        },
        startTime: {
          title: 'Booking Date & Time'
        },
        spaceType: {
          title: 'Space Type'
        },
        duration: {
          title: 'Duration',
          filter: false
        },
        durationMonth: {
          title: 'Duration Month',
          filter: false
        },
        headCount: {
          title: 'Seats',
          filter: false
        },
        spaceUsageFee: {
          title: 'Usage Fee',
          filter: false
        },
        redeemedPoints: {
          title: 'FP Redeemed',
          filter: false
        },
        gstFee: {
          title: 'GST Fee',
          filter: false
        },
        status: {
          title: 'Status'
        },
        settlementStatus: {
          title: 'Settlement Status'
        }
      }
    };


  }

  ngOnInit() {
    //this.getBookingsDetails();
    this.filterdata()
    // this.getBookingsDetailsForRequestDateRange(this.fromRequestDate,this.toRequestDate);
  }
  downloadCSV() {

    var bookingsForDownload = [];
    var headings = ["Booking Id","Customer Name","Customer Email","Customer Phone","Space Name","GoFloaters Space Name","Space Display Name","Original Name","Booking Key","Space Type","Space SubType","Locality","City","Requested Date","Booking Date & Time","Booking Month","Booking Year","Check In Time","Check Out Time","Person Hours","Duration (hrs)","Duration Rounded (hrs)","Duration (months)","Headcount","Usage Fee","Payment Made","FP Redeemed","GST Fee","Status","Cancellation Reason","Payment Ref","Payment Captured?","Payment Transferred?","Revenue Share","Cosmos ID","Settlement Status","WeWork Order Number","AWFIS Order Number","Cowrks Order Number","Innov8 Order Number"]
    for (let booking of this.filteredbookings ) {
      if (!booking.durationMonth) {
        booking["durationMonth"] = 0;
      }
      var bookingObject = (({bookingid, customerName,customerEmail, customerPhoneNumber, spaceName, gofloatersSpaceName ,spaceDisplayName,originalName,bookingkey, spaceType,spaceSubType, locality,city, reqDate,startTime,bookingMonth,bookingYear, checkin,checkout,totalduration, duration,roundedDurationHours,durationMonth,headCount,spaceUsageFee,paymentMade,redeemedPoints,gstFee,status,reasonForCancellation, paymentRefNumber,captured,transferred,gofRevenueShare,cosmosID}) => ({ bookingid, customerName, customerEmail, customerPhoneNumber,spaceName,gofloatersSpaceName,spaceDisplayName,originalName,bookingkey, spaceType,spaceSubType, locality, city, reqDate,startTime,bookingMonth,bookingYear, checkin, checkout, totalduration,duration,roundedDurationHours,durationMonth,headCount,spaceUsageFee,paymentMade,redeemedPoints,gstFee,status,reasonForCancellation, paymentRefNumber,captured,transferred,gofRevenueShare,cosmosID }))(booking);
      bookingObject["reqDate"] = this.getdatetime(bookingObject["reqDate"]);

      if(typeof bookingObject["checkin"] != 'undefined' ) {
        bookingObject["checkin"] = this.getdatetime(bookingObject["checkin"]);
      }else {
        bookingObject["checkin"] = "No checkin";
      }
      bookingObject["city"] = booking.spaceaddress? booking.spaceaddress.city: "";
      bookingObject["locality"] = booking.spaceaddress? booking.spaceaddress.locality: "";
      if(typeof bookingObject["checkout"] != 'undefined' ) {
        bookingObject["checkout"] = this.getdatetime(bookingObject["checkout"]);
      }else {
        bookingObject["checkout"] = "No checkout";
      }
      // bookingObject["checkin"] = this.getdatetime(bookingObject["checkin"]);
      // bookingObject["checkout"] = this.getdatetime(bookingObject["checkout"]);

      bookingObject["bookingMonth"] = this.moment(booking.bookingdate).tz('Asia/Kolkata').format('MMM');
      bookingObject["bookingYear"] = this.moment(booking.bookingdate).tz('Asia/Kolkata').format('YYYY');
      bookingObject["settlementStatus"] = this.getSettlementStatus(bookingObject);

      if(booking && booking.paymentRefNumber) {
        switch (booking.spaceType) {
          case "Restaurant":
          case "Cafe":
            
            if(booking.bookingType == "Day"){
              bookingObject["totalduration"] = 8 * booking.headCount;
            }else {
              if(booking.roundedDurationHours) {
                bookingObject["totalduration"] = booking.roundedDurationHours * booking.headCount;
              }else {

                if( booking.paymentRefNumber == "Redeemed") {
                  if(booking.redeemedPoints == 0) {
                    bookingObject["totalduration"] =  0;
                    // bookingObject["roundedDurationHours"] = 0;
                  }else {
                      bookingObject["totalduration"] =  booking.redeemedPoints / booking.priceperhr;   
                  }
                  bookingObject["roundedDurationHours"] = bookingObject["totalduration"] / booking.headCount;
                }else {
                  if(bookingObject["redeemedPoints"] > 0) {
                    bookingObject["totalduration"] = ( booking.redeemedPoints +( booking.paymentMade / 1.18 )) / booking.priceperhr;
                  }else {
                    bookingObject["totalduration"] = (booking.paymentMade / 1.18) / booking.priceperhr;
                  }
                  bookingObject["roundedDurationHours"] = bookingObject["totalduration"] / booking.headCount;
                }
               
              }
              // bookingObject["roundedDurationHours"] = bookingObject["totalduration"];
            }
            break;
          case "Training Room":
          case "Conference Room":
            if(!booking.roundedDurationHours) {
              bookingObject["roundedDurationHours"] =  (booking.paymentMade / 1.18) / booking.priceperhr;
            }
          
          bookingObject["totalduration"] = bookingObject["roundedDurationHours"];
          
            break;
          case "Shared Workspace":
          if(booking.bookingType == "Day"){
            bookingObject["roundedDurationHours"] = 8
          }else {
            bookingObject["roundedDurationHours"] = 20 * 8 * booking.durationMonth

          }
          bookingObject["totalduration"] =  bookingObject["roundedDurationHours"] * booking.headCount;
          bookingObject["duration"] = bookingObject["totalduration"];
            break;
          default:
            bookingObject["totalduration"] = 0;
            break;
        }
        if(typeof bookingObject["redeemedPoints"] =='undefined' ) {
          bookingObject["redeemedPoints"] = 0;
        }
      }else {
        bookingObject["totalduration"] = 0;
        bookingObject["roundedDurationHours"] = bookingObject["totalduration"];
      }
      if (booking.wework) {
        if (booking.wework.orderNumber)  {
          bookingObject["weworkOrderNumber"] = booking.wework.orderNumber;
        }
      }
      if (booking.awfis) {
        if (booking.awfis.orderNumber)  {
          bookingObject["awfisOrderNumber"] = booking.awfis.orderNumber;
        }
      }
      if (booking.cowrks) {
        if (booking.cowrks.orderNumber)  {
          bookingObject["cowrksOrderNumber"] = booking.cowrks.orderNumber;
        }
      }
      if (booking.innov8) {
        if (booking.innov8.orderNumber)  {
          bookingObject["innov8OrderNumber"] = booking.innov8.orderNumber;
        }
      }
      bookingsForDownload.push(bookingObject);
    }
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: { headers: (headings) }, 
      showTitle: false,
      useBom: true
    };
    new Angular2Csv(bookingsForDownload, 'GoFloaters Report',{ headers: headings });
  }

  // refresh() {
  //   //this.getBookingsDetails();
  //   this.getBookingsDetailsForRequestDateRange(this.fromRequestDate,this.toRequestDate);
  // }

  getSettlementStatus(booking) {
    let settlementStatus = "";
    // Set the booking settlement flag
    if (!booking.settlement && (booking.status.toLowerCase() == 'prepaid' || booking.status.toLowerCase() == 'checkin' || booking.status.toLowerCase() == 'checkout' || booking.status.toLowerCase() == 'closed')) {
      settlementStatus =  "Yet to request";    
    } else {
      if (booking.status.toLowerCase() == 'prepaid' || booking.status.toLowerCase() == 'checkin' || booking.status.toLowerCase() == 'checkout' || booking.status.toLowerCase() == 'closed') {
        if (booking.settlement) {
          if (booking.settlement.settlementRefNumber) {
            settlementStatus = "Settled";
          } else {
            settlementStatus = "Requested";
          }
        }
      } else {
        settlementStatus = "Not Applicable";
      }
    }
    return settlementStatus;
  }

  getBookingsDetailsForRequestDateRange(searchByData) {
    this
      .loader
      .display(true);
    this
      ._bookingservice
      .getbookinglistSearchBy(searchByData)
      .then( (res : any) => {
        this.bookings = res;
        this.loader.display(false);
        this.filterBookings();
        // if(this.sharedService.isSpaceCollectionLoaded)
        // { 
        //   this.filterBookings();
        // }
        // else
        // {
        //   this.sharedService.getSpacesList().then(val => {
        //     this.filterBookings();
        //   });
        // }       
      });
  }

  filterBookings()
  {
    
    this.filteredbookings = [];
    for (let booking of this.bookings ) {
      let spaceInfo = this.sharedService.getSpaceDetailsFromSpaceCollection(booking.spaceId);
      // Shyam 3feb 2024 >> Bookings now have Space Information
      // if(spaceInfo.spaceName != '')
      // {
      //   booking.spaceName = spaceInfo.spaceName;
      // }        
      // // booking.originalSpaceName = spaceInfo.originalSpaceName;
      // booking.gofloatersSpaceName = spaceInfo.gofloatersSpaceName;
      // booking.spaceDisplayName = spaceInfo.spaceDisplayName;
      // booking.spaceSubType = spaceInfo.spaceSubType;
      booking.startTime = this.getdatetime(booking.startTime);
      //If booking level override for gofRevenueShare is  not there then take it from the space level
      if (!booking.gofRevenueShare) {
        booking.gofRevenueShare = spaceInfo.gofRevenueShare;
      }
      booking.settlementStatus = this.getSettlementStatus(booking);
      if( booking.spaceaddress && booking.spaceaddress.locality ) {
        booking["location"] = booking.spaceaddress.locality;
          }     
      this.filteredbookings.push(booking);
    }
  }

  getBookingsDetails() {
    this
      .loader
      .display(true);
    const data = {};
    this
      ._bookingservice
      .getbookinglist(data)
      .then((res:any) => {
        this.bookings = res;
        this.filteredbookings = res;
        //this.filterdata();
        this
          .loader
          .display(false);
      });

  }
  filterdata() {

    if(this.searchby !=='requestDate' && this.searchby !=='bookingDate') {
      this.getBookingsDetailsForRequestDateRange( {searchby: this.searchby, searchvalue: this.searchvalue});
    }else {
          // let fromRequestDateTimestamp = new Date(this.fromRequestDate).getTime();
    // //Do GMT offset. We are doing a (-) to go back to GMT time
    // fromRequestDateTimestamp = fromRequestDateTimestamp - 5.5*60*60*1000;
    const fromRequestDateTimestamp =  Number(this.moment(this.fromRequestDate).tz('Asia/Kolkata'));
    // let toRequestDateTimestamp = new Date(this.toRequestDate).getTime();
    // //Do GMT offset. We are doing a (-) to go back to GMT time
    // toRequestDateTimestamp = toRequestDateTimestamp - 5.5*60*60*1000;
    const toRequestDateTimestamp = Number(this.moment(this.toRequestDate).tz('Asia/Kolkata'));
    console.log("Filter date range : " + fromRequestDateTimestamp + " " + toRequestDateTimestamp);
    
      this.getBookingsDetailsForRequestDateRange({ searchby: this.searchby, fromRequestDate:fromRequestDateTimestamp, toRequestDate:toRequestDateTimestamp} );
    }

   
    
    // var reformatedFilterDate = this.reformatDate(this.currdate);
    // this.filteredbookings = this
    //   .bookings
    //   .filter(item => this.getdate(item.reqDate) === reformatedFilterDate);
  }
  clearFilter() {
    this.filteredbookings = this.bookings;
    //this.currdate = "";
  }
  reformatDate(dateStr)
  {
    var dArr = dateStr.split("-"); // ex input "2018-01-12"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "12/01/2018"
  }
  getTime(time) {
    return this
      .moment(time).tz('Asia/Kolkata')
      .format('h:mm a');
  }
  getdate(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY');
  }
  getdatetime(val) {
    return this
      .moment(val).tz('Asia/Kolkata')
      .format('DD/MM/YYYY, h:mm a');
  }
  converttime(timestamp) {

    const date = new Date(timestamp * 1000);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = '0' + date.getSeconds();
    // Will display time in 10:30:23 format
    const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return formattedTime;
  }
}
